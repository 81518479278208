<style scoped>
.nav-link {
  color: white;
  font-size: .75rem;
}

.active {
  background-color: blue;
}

.sidebar-cont {
  min-height: 100vh;
}
.logo-collapsed{
  font-size: 8px;
}
</style>
<template>
  <!-- Sidebar -->
  <div :class="{ 'sidebar-collapsed': localCollapsed && !isPinned }" @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave" class=" textSt h-100 navbarSt">
    <div class="row">
      <a href="/cuentasPorCobrar" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto textSt text-decoration-none col-9"
        style="height: 3.8rem;" v-if="!isAssex">
        <i class="bi bi-rocket-takeoff px-3 pt-3"></i>
        <span v-if="!localCollapsed || isPinned" class="fs-4 mt-3"><b>FRONTERA</b></span>
      </a>
      <a href="/cuentasPorCobrar" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto textSt text-decoration-none col-9"
        style="height: 3.8rem;"  v-if="isAssex">
        <div class="m-3 mt-4 pt-3" :class="{ 'logo-collapsed': localCollapsed && !isPinned }">
          <img src="./../../img/ASSEX.png" width="100%" alt="" srcset="">
          <div class="text-center">
            <small >by FRONTERA</small>
          </div>
       
        </div>
     
        <span v-if="!localCollapsed || isPinned" class="fs-4 mt-3"></span>
      </a>
      <i v-if="isPinned" @click="togglePin(false)" class="bi bi-pin-fill pt-4 col-3"></i>
      <i v-if="!isPinned && !localCollapsed" @click="togglePin(true)" class="bi bi-pin  pt-4 col-3"></i>
    </div>


    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="mt-2 mb-2" v-for="modulo in modules">
        <a :href="modulo.url" class="nav-link textSt" :class="{ active: currentUrl == modulo.url }">
          <i :class="modulo.icon" class=" px-2"></i>
          <span v-if="!localCollapsed || isPinned">{{ modulo.module.name }}</span>
        </a>
      </li>
    </ul>

  </div>

  <!-- End of Sidebar -->
</template>

<script>
export default {
  components: {
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
    pinned: {
      type: Boolean,
      required: false,
    },
  },
  data() {

    return {
      localCollapsed: this.collapsed,
      isAssex:true,
      theme: localStorage.getItem('theme') || 'light-mode',
      currentUrl: '',
      isPinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
      user: {},
      modules:[]
    }
  },
  watch: {
    collapsed(newVal) {
      this.localCollapsed = newVal; // Mantén la variable local sincronizada
    },
    pinned(newVal) {

      this.isPinned = newVal; // Mantén la variable local sincronizada
    },
  },
  mounted() {
    this.currentUrl = window.location.pathname;
    document.body.className = this.theme;
    let sessionData = localStorage.getItem('user');
    console.log(sessionData);
    this.user = JSON.parse(sessionData);
    this.isAssex = this.user.company.company_name == 'Assex' ? true : false;
    this.getModule(this.user.id_user);

   

  },
  methods: {
    async validateUrl(modules, url){
      if(url == '/alerts'){
        return true
      }
    
      return modules.some(
        (item) => item.url === url  
      );
    },
    async getModule(id) {
        try {
        const response = await axios.get('/api/getUserModulesWithActions/' + id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
        
        this.modules = response.data.data;
     
        this.modules = this.modules.map(ind=>{
          if(ind.module.name == 'dasboard') { 
            ind.icon = 'bi bi-speedometer'
            ind.url = '/dashboard'
           
          }
         
          if(ind.module.name == 'Roles') { 
            ind.icon = 'bi bi-speedometer'
            ind.url = '/roles'
           
          }
          if(ind.module.name.toLowerCase() == 'Cuentas por cobrar'.toLowerCase()) { 
            ind.icon = 'bi bi-pass'
            ind.url = '/cuentasPorCobrar'
            
          }
          if(ind.module.name.toLowerCase() == 'Clientes'.toLowerCase()) { 
            ind.icon = 'bi bi-people'
            ind.url = '/clientes'
            
          }
          if(ind.module.name.toLowerCase() == 'Pagos a proveedores'.toLowerCase()) { 
            ind.icon = 'bi bi-person'
            ind.url = '/pagoProveedores'
          
          }
          if(ind.module.name.toLowerCase() == 'Servicios por cobrar'.toLowerCase()) { 
            ind.icon = 'bi bi-speedometer'
            ind.url = '/serviciosCobrar'
           
          }
          if(ind.module.name.toLowerCase() == 'Despachos'.toLowerCase()) { 
            ind.icon = 'bi bi-box'
            ind.url = '/despachos'
           
          }
          if(ind.module.name.toLowerCase() == 'Notas de cobro'.toLowerCase()) { 
            ind.icon = 'bi bi-file'
            ind.url = '/notascobro'
         
          }
          if(ind.module.name.toLowerCase() == 'Usuarios'.toLowerCase()) { 
            ind.icon = 'bi bi-person'
            ind.url = '/usuarios'
           
          }
          if(ind.module.name.toLowerCase() == 'Divisa'.toLowerCase()) { 
            ind.icon = 'bi bi-cash'
            ind.url = '/divisa'
           
          }
          if(ind.module.name.toLowerCase() == 'Estado cuenta'.toLowerCase()) { 
            ind.icon = 'bi bi-file-text-fill'
            ind.url = '/estadoCuenta'
         
          }
          if(ind.module.name.toLowerCase() == 'Compañia de transporte'.toLowerCase()) { 
            ind.icon = 'bi bi-truck'
            ind.url = '/transportCompany'
         
          }
          return ind
        })
        
        let resp = await this.validateUrl(this.modules, this.getFirstPart(this.currentUrl))
        if (!resp){
          window.history.back();
        }
       
      } catch (error) {
        console.error('Error fetching concepts:', error);
      }
    },
    getFirstPart(url) {
      // Divide la cadena en partes por "/"
      const parts = url.split("/").filter(Boolean); // Filtra vacíos si hay al inicio
      return parts.length > 0 ? `/${parts[0]}` : "/"; // Retorna la primera parte con "/"
    },
    handleMouseOver() {

      if (this.collapsed) {
        this.$emit('update:collapsed', false); // Expande
      }
    },
    handleMouseLeave() {

      if (!this.collapsed) {
        this.$emit('update:collapsed', true); // Colapsa
      }
    },
    togglePin(status) {
      this.isPinned = status; // Cambia el estado de "anclaje"
      localStorage.removeItem('pinned');
      localStorage.setItem('pinned', status);
      this.$emit('update:pinned', status);
    },
  },
};
</script>
