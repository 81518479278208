<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 1rem 3rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3" v-if="edit_mode"><b>Editar rol #</b> {{ rolUpdt.id_rol }}</h2>
                        <h2 class="fw-normal mb-4 me-3" v-else> <b>Crear rol</b></h2>
                    </div>
                    <div class="row p-4">
                        <div class="col-6 mb-4">
                            <label for="name" class="form-label">Nombre</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.name.err }"
                                id="name" placeholder="" v-model="dataForm.name.value" @blur="handlerBlur">
                            <span v-if="dataForm.name.err" class="text-danger">Debe ingresar
                                el nombre</span>
                        </div>
                        <div class="col-11 mb-4">
                            <label for="description" class="form-label">Descripcion</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.description.err }" id="description" placeholder=""
                                v-model="dataForm.description.value" @blur="handlerBlur">
                            <span v-if="dataForm.description.err" class="text-danger">Debe ingresar
                                el nombre</span>
                        </div>
                        <hr>
                        <h4 class="text-black">Modulos</h4>
                        <div class="mt-3 mb-3" style="overflow-y: auto; height: 40vh">
                            <div v-for="mod in modules" :key="mod.id" class="module">
                                <h6 class="text-black">{{ mod.name }}</h6>
                                <div v-for="action in mod.actions" :key="action.id_action" class="action">
                                    <label>
                                        <input type="checkbox" :value="action.id_action"
                                            :checked="isChecked(mod.id, action.id_action)"
                                            @change="togglePermission(mod.id, action.id_action)" />
                                        {{ action.name }}
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <template v-if="edit_mode">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditRol()">
                                Guardar
                            </button>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditRol()">
                                Crear
                            </button>
                        </template>
                        <a class="btn btn-md text-white bg-danger"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            href="/roles">Cancelar</a>

                    </div>

                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            roleName: "",
            modules: [],
            permissions: [],
            idClient: 0,
            rolUpdt: [],
            dataForm: {
                sending: false,
                moduleRol: [],
                name: {
                    value: '',
                    err: false
                },
                description: {
                    value: '',
                    err: false
                },
            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.id_company = this.user.company.id_company;

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        this.edit_mode = false;
        this.edit_id = 0;
        this.cliente = {};
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.edit_mode = true;
                this.edit_id = Number(parts[3]);
                this.getRol(this.edit_id);
            }
        }
        this.fetchModulesWithActions();

    },
    computed: {

    },
    methods: {
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        isChecked(moduleId, actionId) {
            return this.permissions.some(
                (perm) => perm.id_module === moduleId && perm.id_action === actionId
            );
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        async fetchModulesWithActions() {
            try {
                const response = await axios.get("/api/modules-with-actions", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                })
                this.modules = response.data.filter(mod => mod.name != 'Dashboard');
                console.log(this.rolUpdt);
                console.log(this.modules);
            } catch (error) {
                console.error("Error al cargar los módulos y acciones:", error);
            }
        },
        togglePermission(moduleId, actionId) {
            console.log(moduleId, actionId);

            const index = this.permissions.findIndex(
                (perm) => perm.id_module === moduleId && perm.id_action === actionId
            );

            if (index === -1) {
                this.permissions.push({ id_module: moduleId, id_action: actionId });
            } else {
                this.permissions.splice(index, 1);
            }
        },
        async getRol(id_rol) {
            this.$swal({
                title: 'Obteniendo rol'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/roles/' + id_rol, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response)
                if (response.status == 200) {
                    self.rolUpdt = response.data;

                    self.dataForm.name.value = self.rolUpdt.name;
                    self.dataForm.description.value = self.rolUpdt.description;
                    self.rolUpdt.module_action_roles.forEach((rolaction) => {
                        if (rolaction.status != 0) {
                            self.togglePermission(rolaction.id_module, rolaction.id_action);
                        }
                    });

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.rolUpdt = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });;
        },
        createOrEditRol() {
            const self = this;
            // console.log(self);
            // console.log(self.formData);
            self.dataForm.sending = true;

            let foundError = false;

            if (self.dataForm.name.value == '') {
                self.dataForm.name.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.name.err = false;
            }
            if (self.dataForm.description.value == '') {
                self.dataForm.description.err = true;
                foundError = true;
                //return false;
            } else {
                self.dataForm.description.err = false;
            }
            let chmsg = "Creando rol"
            if (self.edit_mode) {
                chmsg = 'Editando rol...' // para editar
            }

            self.$swal({
                title: chmsg
            });
            self.$swal.showLoading();

            console.log(self.dataForm);

            let urlpost = '/api/store-role' // para crear
            let req = {
                    name: self.dataForm.name.value,
                    description: self.dataForm.description.value,
                    permissions: self.permissions,
                }
            if (this.edit_mode) {
                urlpost = '/api/update-role' // para editar
                req = {
                    id_rol: self.edit_id,
                    name: self.dataForm.name.value,
                    description: self.dataForm.description.value,
                    permissions: self.permissions,
                }
            }

            axios.post(urlpost,req
                ,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    self.$swal.close();
                    if (response.status == 201) {

                        self.$swal({
                            title: "Rol agregado exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            window.location.href = '/roles';
                        })


                    } else if (response.status == 200) {

                        self.$swal({
                            title: "Rol editado exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            window.location.href = '/roles';
                        })

                    } else {
                        console.log(response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: "Error al crear rol",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {
                    self.$swal.close();

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            console.log(error.response.data.errors.rut)
                            self.$swal.fire({
                                icon: "error",
                                title: "Error!",
                                text: "Error al generar rol.",
                                showConfirmButton: true,
                                showCancelButton: false
                            });
                        }
                    } else {
                        console.log(error);

                        self.$swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "Error al generar rol.",
                            showConfirmButton: true,
                            showCancelButton: false
                        });


                    }
                });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}

.module {
    margin-bottom: 1.5rem;
}

.action {
    margin-left: 1.5rem;
}
</style>