import './bootstrap';

import * as Popper from "@popperjs/core"
window.Popper = Popper;

import {createApp} from 'vue'

import '../sass/app.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//component
import BarTop from './component/BarTop.vue';
import DropFile from './component/DropFile.vue';
import Navbarra from './component/NavBar.vue';
import PreviewFile from './component/PreviewFile.vue';
// currencies
import CurrenciesList from './currencies/CurrenciesList.vue';
// shipping
import ShippingList from './shipping/ShippingList.vue';
import ShippingCreate from './shipping/ShippingCreate.vue';
import ShippingEdit from './shipping/ShippingEdit.vue';
import PackageCreate from './shipping/PackageCreate.vue';
// cuentas por cobrar
import BillingNoteList from './billingNote/BillingNoteList.vue';
import BillingNoteCreate from './billingNote/BillingNoteCreate.vue';
// compañia de transporte
import TransportCompanyList from './transportCompany/TransportCompanyList.vue';
import TransportCompanyCreate from './transportCompany/TransportCompanyCreate.vue';
// cuentas por cobrar
import AccountsReceivable from './accountsReceivable/AccountsReceivable.vue';
import ServiceToBeCollect from './accountsReceivable/ServiceToBeCollect.vue';
import SupplierPayment from './accountsReceivable/SupplierPayments.vue';
import ServiceToBeCollectCreate from './accountsReceivable/create/ServiceToBeCollectCreate.vue';
import SupplierPaymentCreate from './accountsReceivable/create/SupplierPaymentCreate.vue';
import PassPaymentCreate from './accountsReceivable/create/PassPaymentCreate.vue'
import SupplyPassPaymentCreate from './accountsReceivable/create/SupplyPassPaymentCreate.vue'
import ServicePassPayment from './accountsReceivable/abono/ServicePassPayment.vue'
import ServicePayment from './accountsReceivable/abono/ServicePayment.vue'
// clientes
import UsersList from './users/UsersList.vue';
// crea usuarios
import UsersCreate from './users/UsersCreate.vue';
//roles
import RolesList from './roles/RolesList.vue';
import RolesCreate from './roles/RolesCreate.vue';

// clientes
import ClientsList from './clients/ClientsList.vue';
import ClientsCreate from './clients/ClientsCreate.vue';
import ClientsSupplies from './clients/ClientsSupplies.vue';
import ClientsBanks from './clients/ClientsBanks.vue';
import ClientsConcepts from './clients/ClientsConcepts.vue';
// estado cuenta
import AccountStatus from './accountStatus/AccountStatus.vue';
// login
import Login from './login/Login.vue';

// dashboard
import Dashboard from './dashboard/Dashboard.vue';

// alerts
import AlertsList from './alerts/AlertsList.vue';


const login = createApp(Login);
login.use(VueSweetalert2)
login.mount("#login")
//
// account status


const accountStatus = createApp(AccountStatus);
accountStatus.use(VueSweetalert2)
accountStatus.mount("#accountStatus")
//AccountsReceivable
const alertsList = createApp(AlertsList);
alertsList.use(VueSweetalert2)
alertsList.mount("#alerts")
//AccountsReceivable
const accountsReceivable = createApp(AccountsReceivable);
accountsReceivable.use(VueSweetalert2)
accountsReceivable.mount("#accountsReceivable")
//ServiceToBeCollect
const serviceToBeCollect = createApp(ServiceToBeCollect);
serviceToBeCollect.use(VueSweetalert2)
serviceToBeCollect.mount("#serviceToBeCollect")
//SupplierPayments
const supplierPayment = createApp(SupplierPayment);
supplierPayment.use(VueSweetalert2)
supplierPayment.mount("#supplierPayment")
//transport company
const transportCompanyList = createApp(TransportCompanyList);
transportCompanyList.use(VueSweetalert2)
transportCompanyList.mount("#transportCompanyList")
//transport company
const transportCompanyCreate = createApp(TransportCompanyCreate);
transportCompanyCreate.use(VueSweetalert2)
transportCompanyCreate.mount("#transportCompanyCreate")
//shipping
const shippingList = createApp(ShippingList);
shippingList.use(VueSweetalert2)
shippingList.mount("#shipping-list")
//billing note
const billingNote = createApp(BillingNoteList);
billingNote.use(VueSweetalert2)
billingNote.mount("#billing-note")
// create
//ServiceToBeCollect create
const serviceToBeCollectCreate = createApp(ServiceToBeCollectCreate);
serviceToBeCollectCreate.use(VueSweetalert2)
serviceToBeCollectCreate.mount("#serviceToBeCollect-create")
//serviceSupplierPayments
const servicePassPayment = createApp(ServicePassPayment);
servicePassPayment.use(VueSweetalert2)
servicePassPayment.mount("#service-supply-pass-payment")
// service payment
const servicePayment = createApp(ServicePayment);
servicePayment.use(VueSweetalert2)
servicePayment.mount("#service-pass-payment")
//SupplierPayments
const supplierPaymentCreate = createApp(SupplierPaymentCreate);
supplierPaymentCreate.use(VueSweetalert2)
supplierPaymentCreate.mount("#supplierPayment-create")
// pass payment
const passPaymentCreate = createApp(PassPaymentCreate);
passPaymentCreate.use(VueSweetalert2)
passPaymentCreate.mount("#pass-payment")
// supply pass payment
const supplyPassPaymentCreate = createApp(SupplyPassPaymentCreate);
supplyPassPaymentCreate.use(VueSweetalert2)
supplyPassPaymentCreate.mount("#supply-pass-payment")
//shipping
const shippingListCreate = createApp(ShippingCreate);
shippingListCreate.use(VueSweetalert2)
shippingListCreate.mount("#shipping-create")
//shipping
const shippingListEdit = createApp(ShippingEdit);
shippingListEdit.use(VueSweetalert2)
shippingListEdit.mount("#shipping-edit")
//shipping PackageCreate
const packageCreate = createApp(PackageCreate);
packageCreate.use(VueSweetalert2);
packageCreate.mount("#packageCreate");

//billing note
const billingNoteCreate = createApp(BillingNoteCreate);
billingNoteCreate.use(VueSweetalert2)
billingNoteCreate.mount("#billing-note-create")
// usuarios
const userList = createApp(UsersList);
userList.use(VueSweetalert2);
userList.mount("#user-list");

// crear usuarios 
const userCreate = createApp(UsersCreate);
userCreate.use(VueSweetalert2);
userCreate.mount("#user-create");

// roles
const rolesList = createApp(RolesList);
rolesList.use(VueSweetalert2);
rolesList.mount("#rol-list");
const rolesCreate = createApp(RolesCreate);
rolesCreate.use(VueSweetalert2);
rolesCreate.mount("#rol-create");
// clientes
const clientList = createApp(ClientsList);
clientList.use(VueSweetalert2);
clientList.mount("#client-list");
// crear clientes 
const clientCreate = createApp(ClientsCreate);
clientCreate.use(VueSweetalert2);
clientCreate.mount("#client-create");
// provisiones clientes
const clientSupply = createApp(ClientsSupplies);
clientSupply.use(VueSweetalert2);
clientSupply.mount("#client-supply");
// banco clientes
const clientConcept = createApp(ClientsConcepts);
clientConcept.use(VueSweetalert2);
clientConcept.mount("#client-concept");
// concepto clientes
const clientBanks = createApp(ClientsBanks);
clientBanks.use(VueSweetalert2);
clientBanks.mount("#client-bank");
// currencies list
const currenciesList = createApp(CurrenciesList);
currenciesList.use(VueSweetalert2);
currenciesList.mount("#currencies-list");
// dashboard
const dashboard = createApp(Dashboard);
dashboard.use(VueSweetalert2);
dashboard.mount("#dashboard");




//component
createApp(Navbarra).mount("#nav-bar")
createApp(PreviewFile).mount("#preview-file")
createApp(BarTop).mount("#bar-top")
createApp(DropFile).mount("#drop-file")