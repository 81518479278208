<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.modal-mb {
    position: fixed;
    background: #00000094;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-dialo {
    width: 80vw;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>{{ edit_mode ? 'Editando ' : 'Crea un ' }} cobro por servicio
                                {{ edit_mode ? '#' + edit_id : '' }}</b>
                        </h2>
                        <p>{{ edit_mode ? 'Editar' : 'Crear' }} un cobro propio de la empresa directo hacia el cliente
                            (como
                            cobros por administración), asociado a un despacho.
                        </p>
                    </div>
                    <div class="row pt-4 px-1">

                        <div class="col-4 mb-4">
                            <label for="client" class="form-label" v-if="edit_mode">Cliente:</label>
                            <label for="client" class="form-label" v-else>Seleccionar cliente:</label>

                            <select disabled @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value" v-if="edit_mode">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>

                            <select @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value" v-else>
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>


                            <span v-if="dataForm.client.err" class="text-danger">Debe seleccionar un cliente</span>
                        </div>

                        <div class="col-4 mb-4 d-none">
                            <label for="concept" class="form-label">Seleccionar concepto</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.concept.value">
                                <option v-for="concept in concepts" :value="concept.name">{{ concept.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.concept.err" class="text-danger">Debe seleccionar un concepto</span>
                        </div>
                    </div>

                    <div class="row pt-0 px-1">

                        <div class="col-4 mb-4">
                            <label for="currency" class="form-label" v-if="edit_mode">Divisa</label>
                            <label for="currency" class="form-label" v-else>Seleccionar divisa</label>

                            <select disabled class="form-select" aria-label="Default select example"
                                v-model="dataForm.currency.value"
                                @change="formatAmountAndUpdate(dataForm.amount, dataForm.currency)" v-if="edit_mode">
                                <option v-for="currency in currencies" :value="currency.id_currency">{{ currency.name }}
                                </option>
                            </select>

                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currency.value"
                                @change="formatAmountAndUpdate(dataForm.amount, dataForm.currency)" v-else>
                                <option v-for="currency in currencies" :value="currency.id_currency">{{ currency.name }}
                                </option>
                            </select>


                            <span v-if="dataForm.currency.err" class="text-danger">Debe seleccionar una divisa</span>
                        </div>

                        <div class="col-4 mb-4">
                            <label for="amount" class="form-label">Monto</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                                id="amount" placeholder="" v-model="dataForm.amount.value_formated"
                                @input="formatAmountAndUpdate(dataForm.amount, dataForm.currency)" @blur="handlerBlur">
                            <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                        </div>
                    </div>

                    <div class="row pt-4 px-1">


                        <div class="col-4 mb-4">
                            <label for="id_cs_fee_status" class="form-label">Estado</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.id_cs_fee_status.value"
                                @change="statusUpdated(dataForm.id_cs_fee_status.value)">
                                <option v-for="pstatus in allid_cs_fee_status" :value="pstatus.id_cs_fee_status">{{
            pstatus.description }}
                                </option>
                            </select>
                            <span v-if="dataForm.id_cs_fee_status.err" class="text-danger">Debe seleccionar un
                                estado</span>
                        </div>


                        <div class="col-4 mb-4">
                            <label for="date" class="form-label">Fecha emisión factura (fecha aproximada si no se ha
                                emitido)</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date.err" class="text-danger">Debe seleccionar una fecha</span>
                        </div>



                        <div class="row pt-4 px-1">

                            <div class="col-4 mb-4">
                                <label for="bill" class="form-label">Número de factura</label>
                                <input
                                    :disabled="dataForm.id_cs_fee_status.value == 1 || dataForm.id_cs_fee_status.value == 2"
                                    type="text" class="form-control" :class="{ 'border-danger': dataForm.bill.err }"
                                    id="bill" placeholder="" v-model="dataForm.bill.value" @blur="handlerBlur">
                                <span v-if="dataForm.bill.err" class="text-danger">Debe ingresar un número de
                                    factura</span>
                            </div>


                            <div class="col-6 mb-4 px-4"
                                :hidden="dataForm.id_cs_fee_status.value == 1 || dataForm.id_cs_fee_status.value == 2">

                                <div v-if="dataForm2.fileName == ''">
                                    <label class="form-label mb-0">
                                        Adjuntar Factura
                                    </label>
                                    <DropFile
                                        :disabled="dataForm.id_cs_fee_status.value == 1 || dataForm.id_cs_fee_status.value == 2"
                                        @drag-end="updateFileBlob" :files="dataForm.files"
                                        v-if="dataForm2.fileName == ''" />

                                </div>

                                <span v-if="dataForm2.filerr" class="text-danger">
                                    Debe adjuntar una factura</span>


                                <div v-if="dataForm2.fileName != ''">
                                    <label for="value-cot" class="form-label mb-0">Factura adjuntada</label>
                                    <div class="text-dark row">
                                        <a class="col-3">
                                            {{ dataForm2.fileName }}</a>
                                        <div class="col-1" @click="showModal = true"><a
                                                class="dropdown-item text-primary" href="#"> <i
                                                    class="bi bi-eye px-2"></i></a></div>
                                        <div class="col-1" @click="deleteArchive()"><a class="dropdown-item text-danger"
                                                href="#"> <i class="bi bi-trash px-2"></i></a></div>
                                    </div>
                                </div>
                            </div>

                            <PreviewFile v-if="showModal" :blob="dataForm2.fileBlob" :mimeType="dataForm2.mime_type"
                                :visible="showModal" @close="showModal = false" />

                        </div>


                        <div class="text-dark" v-if="dataForm3.isShipping">
                            Este pago esta asociado al despacho: {{ dataForm3.shippingSelect.shipping_number }}
                        </div>

                        <span v-if="dataForm.noShippingErr" class="text-danger">
                            Debe asociar un despacho</span>

                        <vue3-datatable :search="search" :rows="shippings" :columns="columns" order :sortable="true"
                            :sortColumn="'date'">
                            <template #select="data">
                                <button @click="toggleSelectionShipping(data.value)">
                                    {{ isSelectedShipp(data.value) ? 'Desasociar' : 'Asociar' }}
                                </button>
                            </template>
                            <template #amount="data">
                                <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                            </template>
                            <template #client="data">
                                <p>{{ data.value.client.name }} </p>
                            </template>
                            <template #currency="data">
                                <p>{{ data.value.currency.initials }}</p>
                            </template>
                        </vue3-datatable>

                        <div class="text-center text-lg-end mt-4 pt-2">
                            <button type="button" class="btn btn-md text-white mx-2" v-if="!edit_mode"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()">
                                Crear
                            </button>
                            <button type="button" class="btn btn-md text-white mx-2" v-if="edit_mode"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="edit()">
                                Editar
                            </button>
                            <button type="button" class="btn btn-md text-white bg-danger" @click="cancel()"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </main>
        <PreviewFile v-if="showModal" :blob="dataForm2.fileBlob" :mimeType="dataForm2.mime_type" :visible="showModal"
            @close="showModal = false" />
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';
import PreviewFile from './../../component/PreviewFile.vue';
import NumUtils from '../../component/NumUtils.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        VueDatePicker,
        DropFile,
        DataTablesCore,
        NavBar,
        PreviewFile,
        BarTop,
        NumUtils,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            edit_mode: false,
            edit_id: 0,
            verShipping: false,
            showModal: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            concepts: [],
            allid_cs_fee_status: [],
            clients: [],
            paymentMethodes: [
                {
                    id: 1,
                    name: 'Transferencia electronica',

                },
                {
                    id: 2,
                    value: 'Pago tarjeta de credito',

                }, {
                    id: 3,
                    value: 'Pago en efectivo',

                },
            ],
            //idClient: 0,
            dataForm: {
                sending: false,
                // newBill: true,
                noShippingErr: false,

                id_cs_fee_status: {
                    value: '',
                    err: false,
                },

                client: {
                    value: '',
                    err: false
                },
                date: {
                    value: '',
                    err: false
                },
                amount: {
                    value: '',
                    value_formated: '',
                    err: false
                },
                currency: {
                    value: 15,
                    err: false
                },
                concept: {
                    value: 1,
                    err: false
                },
                bill: {
                    value: '',
                    err: false
                },

                isShipping: false,
                files: []
            },
            shippings: [

            ],
            dataForm2: {
                fileName: '',
                mime_type: '',
                file: '',
                filerr: false,
                fileBlob: null
            },
            dataForm3: {
                isShipping: false,
                shippingSelect: {},
            },
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "shipping_number", title: "Número Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "boarding_number", title: "Número de embarque" },
                { field: "booking_number", title: "Número de reserva" },
                { field: "type_operation", title: "Tipo operación" },
                { field: "consignee", title: "Despachador" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "País destino" }
            ],
        }
    },
    async mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        let urlpath = window.location.pathname;
        this.edit_mode = false;
        this.edit_id = 0;
        let parts = urlpath.split('/');
        if (parts.length == 3) {
            if (!isNaN(Number(parts[2]))) {
                this.edit_id = Number(parts[2]);
                this.edit_mode = true
            }
        }

        this.$swal({
            title: 'Cargando...',
            showConfirmButton: false,
            showCancelButton: false
        });
        this.$swal.showLoading();

        await this.getCurrencies();

        await this.getClients();
        await this.getAllidCsFeeStatus();

        let editError = false;
        if (this.edit_mode) {
            await this.getService(this.edit_id);
        }

        this.$swal.close();

    },
    computed: {

    },
    methods: {

        updateFileBlob() {
            let newFile = this.dataForm.files[0];
            this.dataForm2.fileName = newFile["name"];
            this.dataForm2.mime_type = newFile["type"];
            this.dataForm2.fileBlob = this.dataForm.files[0];
            this.dataForm2.filerr = false;
        },

        formatAmountAndUpdate(amountInput, currencySelect) {
            NumUtils.methods.formatAmountAndUpdate(amountInput, currencySelect);
        },

        statusUpdated(id_status) {
            // estado borrador o factura no emitida
            if (id_status == 1 || id_status == 2) {
                // this.dataForm.date.value = "";
                this.dataForm.bill.value = "";
                this.dataForm2.fileName = "";
                this.dataForm2.fileBlob = null;
                this.dataForm.files = [];
                this.dataForm.files.splice(0); // borrar todo
                // this.dataForm.date.err = false;
                this.dataForm.bill.err = false;
                this.dataForm2.filerr = false;

            }

        },


        async getAllidCsFeeStatus() {

            const self = this;

            try {
                const response = await axios.get('/api/cs-fee-statuses', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.allid_cs_fee_status = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
                self.$swal.fire({
                    icon: "error",
                    title: "Error interno",
                    text: "Comuníquese con soporte.",
                    showConfirmButton: true,
                    showCancelButton: false
                });
            }
        },

        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect.id_shipping == data.id_shipping
        },
        base64ToBlob(base64, contentType, sliceSize = 512) {
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        },

        async getService(id) {

            this.$swal.update({ title: 'Cargando data de servicio por cobrar...' });
            this.$swal.showLoading();

            const self = this;

            // await axios.get('/api/services-to-be-collected/' + id, {
            await axios.get('/api/services-to-be-collected/' + id + '/includefile', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response)
                if (response.status == 200) {

                    const data = response.data.data;
                    this.dataForm.client.value = data.id_client;
                    this.dataForm.currency.value = data.id_currency;
                    this.dataForm.date.value = data.date;
                    this.dataForm.amount.value = data.amount;
                    this.dataForm.id_cs_fee_status.value = data.id_status;

                    let amounts = NumUtils.methods.formatAmount(data.amount,
                        data.id_currency)
                    if (amounts['value'] != null) {
                        self.dataForm.amount.value = amounts['value'];
                        self.dataForm.amount.value_formated = amounts['value_formated'];
                    }

                    this.dataForm.concept.value = data.concept;
                    this.dataForm.bill.value = data.bill_number;
                    if (data.file) {
                        // this.dataForm.newBill = false;
                        this.dataForm2.fileName = data.filename;
                        this.dataForm2.mime_type = data.mime_type;
                        // this.dataForm2.file = this.base64ToBlob(data.file, data.mime_type, 512);
                        this.dataForm2.fileBlob = this.base64ToBlob(data.file, data.mime_type, 512);
                    }
                    this.getShippingEdit(data.id_client)
                    if (response.data.shippings.length > 0) {
                        this.dataForm3.shippingSelect = response.data.shippings[0];
                        self.dataForm3.isShipping = true
                    }


                    //self.clientesSelect = response.data.clienteEmpresa;

                } else {
                    self.clients = [];
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error interno 1.1",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: true,
                        showCancelButton: false
                    });

                }

            });
        },
        toggleSelectionShipping(data) {
            if (this.dataForm3.shippingSelect.id_shipping == data.id_shipping) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm.noShippingErr = false;
                this.dataForm3.shippingSelect = data;
            }
        },

        async getClients() {

            const self = this;

            await axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    self.clients = response.data.filter((client) => client.status == 1);
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },

        async getConcepts(idClient) {

            const self = this;

            try {
                const response = await axios.get('/api/getClientConcept/' + idClient, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.concepts = response.data;
                if (this.concepts.length == 0) {
                    this.$swal({
                        title: "No tienes conceptos agregados",
                        text: "Debes ingresar un concepto para generar un servicio por cobrar",
                        showDenyButton: false,
                        cancelButtonText: 'Agregar concepto'
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        window.location.href = '/clientes/concept/' + idClient;
        
                    });
                }else{
                    self.getShipping();
                }
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        deleteArchive() {
            const self = this;
            self.$swal.fire({
                icon: "question",
                title: "Estas seguro que deseas quitar este archivo adjunto?",
                showConfirmButton: true,
                confirmButtonText: "Si, eliminar",
                cancelButtonText: "No, conservar",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.dataForm.newBill = true;
                    this.dataForm2.fileName = "";
                    this.dataForm2.fileBlob = null;
                    this.dataForm.files = [];
                    this.dataForm.files.splice(0); // borrar todo
                }
            });
        },
        async clientSelected(value) {
            //console.log('Selected Option:', value);
            this.selectedOption = value;
            const self = this;
            let idclient = self.dataForm.client.value;
            await this.getConcepts(idclient);
         
        },
        getShippingEdit(idclient) {
            const self = this;
            const req = {
                page: 1,
                per_page: 1000,
                id_client: idclient
            }
            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    self.shippings = response.data.data;
                    setTimeout(() => {
                        this.$swal.close();
                        // Cierra el modal después de 3 segundos
                    }, 2000);

                } else {
                    self.shippings = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },

        getShipping() {
            const self = this;

            let idclient = 0;
            if (self.dataForm.client.value == "") {
                self.shippings = [];
                self.dataForm3.isShipping = false;
                self.dataForm3.shippingSelect = {};
                return;
            } else {
                idclient = self.dataForm.client.value;
                self.dataForm3.isShipping = false;
                self.dataForm.noShippingErr = false;
                self.dataForm3.shippingSelect = {};
            }

            const req = {
                page: 1,
                per_page: 10000,
                id_client: idclient
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.shippings = response.data.data;
                } else {
                    self.shippings = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        cancel() {
            window.location.href = './../serviciosCobrar';
        },

        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data.filter((divisa) => {
                    return divisa.status == 1
                });
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verShipping = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        create() {
            const self = this;
            // console.log(self);
            // console.log(self.formData);
            self.dataForm.sending = true;

            let foundError = false;


            if (self.dataForm.id_cs_fee_status.value == 1 ||
                self.dataForm.id_cs_fee_status.value == 2) {
                // en modo borrador o factura no emitida no se valida documento
                // ni número de documento
            } else {


                if (self.dataForm.bill.value == '') {
                    self.dataForm.bill.err = true;
                    foundError = true;
                } else {
                    self.dataForm.bill.err = false;
                }

                if (self.dataForm.files.length == 0) {
                    self.dataForm2.filerr = true;
                    foundError = true;
                } else {
                    self.dataForm2.filerr = false;
                }
            }

            if (self.dataForm.date.value == '') {
                self.dataForm.date.err = true;
                foundError = true;
            } else {
                self.dataForm.date.err = false;
            }

            if (self.dataForm.amount.value == '') {
                self.dataForm.amount.err = true;
                foundError = true;
            } else {
                self.dataForm.amount.err = false;
            }

            if (self.dataForm.currency.value == '') {
                self.dataForm.currency.err = true;
                foundError = true;
            } else {
                self.dataForm.currency.err = false;
            }

            if (self.dataForm.concept.value == 0) {
                self.dataForm.concept.err = true;
                foundError = true;
            } else {
                self.dataForm.concept.err = false;
            }

            if (self.dataForm.client.value == '') {
                self.dataForm.client.err = true;
                foundError = true;
            } else {
                self.dataForm.client.err = false;
            }

            if (!self.dataForm3.isShipping) {
                self.dataForm.noShippingErr = true;
                foundError = true;
            } else {
                self.dataForm.noShippingErr = false;
            }

            if (foundError) {
                return false;
            }

            this.$swal({
                title: 'Ingresando cobro por servicio...'
            });
            this.$swal.showLoading();

            let id_shipping = null;
            if (self.dataForm3.isShipping) {
                id_shipping = self.dataForm3.shippingSelect["id_shipping"];
            }

            axios.postForm('/api/cs-fees',
                {
                    date_fee: self.dataForm.date.value,
                    amount: self.dataForm.amount.value,
                    id_currency: self.dataForm.currency.value,
                    concept: self.dataForm.concept.value,
                    bill_number: self.dataForm.bill.value,
                    file: self.dataForm2.fileBlob,
                    id_cs_fee_status: self.dataForm.id_cs_fee_status.value,
                    id_client: self.dataForm.client.value,
                    id_shipping: id_shipping
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {

                    self.$swal.fire({
                        icon: "success",
                        title: "Cobro por servicio registrado exitosamente",
                        showConfirmButton: true,
                        showCancelButton: false
                    }).then((result) => {
                        window.location.href = '../../../serviciosCobrar';
                    });
                }).catch(function (error) {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Error interno detectado, comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        },
        edit() {
            const self = this;

            self.dataForm.sending = true;

            let foundError = false;

            let updateDoc = true;
            let deleteDoc = true;
            if (self.dataForm.id_cs_fee_status.value == 1 ||
                self.dataForm.id_cs_fee_status.value == 2) {
                // en modo borrador no se valida ni guarda 
                // documento ni número de doc
            } else {


                if (self.dataForm.bill.value == '') {
                    self.dataForm.bill.err = true;
                    foundError = true;
                } else {
                    self.dataForm.bill.err = false;
                }

                if (self.dataForm.files.length < 1) {
                    if (self.dataForm2.fileBlob != null) {
                        // En modo edición si existe un fileblob 
                        // pero no hay files[], es por que el usuario no ha
                        // reemplazado ni quitado el adjunto
                        updateDoc = false;
                        self.dataForm2.filerr = false;
                    } else {

                        self.dataForm2.filerr = true;
                        foundError = true;
                    }
                } else {
                    deleteDoc = false;
                    self.dataForm2.filerr = false;
                }
            }

            if (self.dataForm.date.value == '') {
                self.dataForm.date.err = true;
                foundError = true;
            } else {
                self.dataForm.date.err = false;
            }

            if (self.dataForm.amount.value == '') {
                self.dataForm.amount.err = true;
                foundError = true;
            } else {
                self.dataForm.amount.err = false;
            }

            if (self.dataForm.currency.value == '') {
                self.dataForm.currency.err = true;
                foundError = true;
            } else {
                self.dataForm.currency.err = false;
            }

            if (self.dataForm.concept.value == 0) {
                self.dataForm.concept.err = true;
                foundError = true;
            } else {
                self.dataForm.concept.err = false;
            }

            if (self.dataForm.client.value == '') {
                self.dataForm.client.err = true;
                foundError = true;
            } else {
                self.dataForm.client.err = false;
            }

            if (!self.dataForm3.isShipping) {
                self.dataForm.noShippingErr = true;
                foundError = true;
            } else {
                self.dataForm.noShippingErr = false;
            }

            // console.log(self.dataForm.files);


            if (foundError) {
                return false;
            }

            this.$swal({
                title: 'Ingresando cobro por servicio...'
            });
            this.$swal.showLoading();



            let id_shipping = null;
            if (self.dataForm3.isShipping) {
                id_shipping = self.dataForm3.shippingSelect["id_shipping"];
            }

            axios.postForm('/api/updateCsFee',
                {
                    update_doc: updateDoc,
                    delete_doc: deleteDoc,
                    date_fee: self.dataForm.date.value,
                    amount: self.dataForm.amount.value,
                    id_currency: self.dataForm.currency.value,
                    concept: self.dataForm.concept.value,
                    bill_number: self.dataForm.bill.value,
                    file: self.dataForm2.fileBlob,
                    id_cs_fee_status: self.dataForm.id_cs_fee_status.value,
                    id_client: self.dataForm.client.value,
                    id_shipping: id_shipping,
                    id_cs_fee: self.edit_id,
                    // newBill : self.dataForm.newBill
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {

                    self.$swal.fire({
                        icon: "success",
                        title: "Cobro por servicio registrado exitosamente",
                        showConfirmButton: true,
                        showCancelButton: false
                    }).then((result) => {
                        window.location.href = '/serviciosCobrar';
                    });
                }).catch(function (error) {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Error interno detectado, comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>