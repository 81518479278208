<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Notas de cobro</h2>
                    <p>Listar todas las notas de cobro.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="notascobro/crear">Agregar nota de cobro</a>
                    </div>
                </div>
                <p>Filtros</p>
                <div class="m-3 row">
                    <div class="col-md-2 mb-4">
                        <label for="rut" class="form-label">RUT cliente</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }" id="rut"
                            placeholder="" v-model="dataForm.rut.value">
                        <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                            RUT</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="name" class="form-label">Nombre cliente</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.name.err }" id="name"
                            placeholder="" v-model="dataForm.name.value">
                        <span v-if="dataForm.name.err" class="text-danger">Debe ingresar un
                            nombre</span>
                    </div>
                    <div class="col-md-3 mb-4">
                        <label for="billing_note_number" class="form-label">Nº nota de cobro</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.billing_note_number.err }" id="billing_note_number"
                            placeholder="" v-model="dataForm.billing_note_number.value">
                        <span v-if="dataForm.billing_note_number.err" class="text-danger">Debe ingresar un
                            número de nota de cobro</span>
                    </div>

                    <div class="col-md-2 mb-4">
                        <label for="amount" class="form-label">Monto</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                            id="amount" placeholder="" v-model="dataForm.amount.value">
                        <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                    </div>
                    <div class="col-md-2 mt-4">
                        <div class="text-end">
                            <a class="btn btn-success" @click="filter()">Filtrar</a>
                        </div>
                    </div>
                </div>
                <div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">

                        <template #is_emit="data">
                            <span v-if="data.value.is_emit == 1">SÍ</span>
                            <span v-else>No</span>
                        </template>

                        <template #emit_amount="data">
                            <span>{{ data.value.emit_amount > 0 ? formatCurrency(data.value.emit_amount,
            data.value.initials) : 0 }}</span>
                        </template>

                        <template #saldo="data">
                            <span>{{ data.value.saldo > 0 ? formatCurrency(data.value.saldo,
            data.value.initials) : 0 }}</span>
                        </template>

                        <template #rut="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                        </template>
                        <!-- Celda personalizada -->
                        <template #emit_date="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.emit_date }}</td>
                        </template>
                        <template #created_at="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.created_at }}</td>
                        </template>
                        <template #actions="data">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Acciones
                                </button>
                                <ul class="dropdown-menu">
                                    <li @click="showBill(data.value)"><a class="dropdown-item" href="#">
                                            <i class="bi bi-eye px-2"></i>Ver</a></li>

                                </ul>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Ver -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Nota de cobro</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <p class="text-black" v-if="(billNote.id_billing_note_status == 3) || (billNote.id_billing_note_status == 4)">Esta nota de cobro fue emitida</p>
                        <p class="text-black" v-else>Esta nota de cobro no fue emitida</p>
                        <div class="card p-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Identificador</b>
                                    <p>{{ billNote.id_billing_note }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Nº nota de cobro</b>
                                    <p>{{ billNote.billing_note_number }}</p>
                                </div>

                                <div class="col-md-4">
                                    <b>Fecha de creación</b>
                                    <p>{{ billNote.created_at }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>RUT cliente</b>
                                    <p>{{ billNote.rut }}</p>
                                </div>
                                <br>
                                <div class="col-md-4">
                                    <b>Nombre cliente</b>
                                    <p>{{ billNote.name }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b v-if="(billNote.id_billing_note_status == 3) || (billNote.id_billing_note_status == 4) ">Monto emitido</b>
                                    <b v-else>Monto a emitir</b>
                                    <p>{{ billNote.emit_amount > 0 ? formatCurrency(billNote.emit_amount,
                                        billNote.initials) : 0
                                        }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Monto impago total</b>
                                    <p>{{ billNote.saldo > 0 ? formatCurrency(billNote.saldo,
                                        billNote.initials) : 0
                                        }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <b>Pagos a proveedor asociados:</b>
                                    <vue3-datatable :search="search" :rows="billNote.pagos" :columns="columnsModal">
                                    
                                    <template #amount="data">
                                        <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount,
                        data.value.initials) : 0 }}</span>
                                    </template>

                                    <template #unpaid_amount="data">
                                        <span>{{ data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount,
                        data.value.initials) : 0 }}</span>
                                    </template>
                                    
                                    </vue3-datatable>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            verModal: false,
            billNote: {},
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            items: [

            ],
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                name: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                billing_note_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                amount: {
                    value: 0,
                    err: false,
                    msjErr: ''
                }
            },
            columns: [
                { field: "id_billing_note", title: "ID" },
                { field: "billing_note_number", title: "Nota de cobro" },
                { field: "emit_date", title: "Fecha emisión" },
                { field: "week_number", title: "Nro. de semana" },
                { field: "status_name", title: "Estado" },
                { field: "created_at", title: "Fecha creación" },
                { field: "rut", title: "Rut cliente" },
                { field: "name", title: "Nombre cliente" },
                { field: "emit_amount", title: "Monto" },
                { field: "saldo", title: "Monto impago" },
                { field: "actions", title: "" }
            ],
            columnsModal: [
                { field: "id_payment", title: "ID" },
                { field: "concept", title: "concepto" },
                { field: "amount", title: "Monto" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "initials", title: "moneda" }
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        filter() {
            const self = this;
            let params = {
                page: 1,
                per_page: 1000,rut: this.dataForm.rut.value, name: this.dataForm.name.value,
                billing_note_number: this.dataForm.billing_note_number.value, amount: this.dataForm.amount.value,
            }
            axios.post('/api/getBillingNotes', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        },
        closeModal() {
            this.verModal = false;
        },
        formatCurrency(value, currencyCode = 'CLP') {

            return  new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showBill(data) {
            this.verModal = true;
            this.billNote = data;
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo notas de cobro'
            });
            this.$swal.showLoading();
            const self = this;
           
            let params = {
                page: 1,
                per_page: 1000,
                rut: this.dataForm.rut.value, name: this.dataForm.name.value,
                billing_note_number: this.dataForm.billing_note_number.value, amount: this.dataForm.amount.value,
            }
            axios.post('/api/getBillingNotes', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data;

                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>