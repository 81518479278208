<style>
.bh-table-responsive {
    min-height: 30vh;
}

.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Pago a proveedores</h2>
                    <p>Estos pagos son los realizados a aduanas, navieras y administradores varios para efectuar los
                        despachos.
                        Se utilizan para calcular cuánto se le cobrará al cliente final y para crear las notas de cobro.
                    </p>
                    <p>
                        Esta tabla incluye el monto impago actual y los datos del último abono recibido.
                    </p>

                    <div class="text-end">
                        <a class="btn btn-success" href="pagoProveedores/crear">Agregar pago a proveedor</a>
                    </div>
                </div>
                <p>Filtros</p>
                <div class="m-3 row">
                    <div class="col-md-2 mb-4">
                        <label for="rut" class="form-label">Rut cliente</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }" id="rut"
                            placeholder="" v-model="dataForm.rut.value">
                        <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                            rut</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="shipping_number" class="form-label">Nro despacho</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.shipping_number.err }" id="shipping_number"
                            placeholder="" v-model="dataForm.shipping_number.value">
                        <span v-if="dataForm.shipping_number.err" class="text-danger">Debe ingresar un
                            número de despacho</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="bill_number" class="form-label">Nro nota cobro</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.bill_number.err }"
                            id="bill_number" placeholder="" v-model="dataForm.bill_number.value">
                        <span v-if="dataForm.bill_number.err" class="text-danger">Debe ingresar un
                            número de nota cobro</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="amount" class="form-label">Monto</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                            id="amount" placeholder="" v-model="dataForm.amount.value">
                        <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                    </div>
                    <div class="col-md-2 mt-4">
                        <div class="text-end">
                            <a class="btn btn-success" @click="filter()">Filtrar</a>
                        </div>
                    </div>
                </div>
                <div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'ID'">
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount,
            data.value.initials) : 0 }}</span>

                        </template>
                        <template #payment_method="data">
                            <span>{{ data.value.payment_method }} </span>
                        </template>
                        <template #value_payment="data">

                            <span>{{ data.value.value_payment > 0 ?
            formatCurrency(data.value.value_payment,
                data.value.value_payment_initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{ data.value.unpaid_amount > 0 ?
            formatCurrency(data.value.unpaid_amount,
                data.value.value_unpaid_initials) : 0 }}</span>
                        </template>
                        <template #rut="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                        </template>
                        <!-- Celda personalizada -->
                        <!--  <template #last_date_change="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.last_date_change }}</td>
                        </template> -->
                        <template #actions="data">
                            <div class="text-center">

                                <div>
                                    <div class="dropdown">

                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Acciones
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li @click="showSupp(data.value)"><a class="dropdown-item" href="#"> <i
                                                        class="bi bi-eye px-2"></i>Ver</a></li>
                                            <li v-if="data.value.unpaid_amount > 0"
                                                @click="addPartialPayment(data.value)"><a class="dropdown-item"
                                                    href="#"> <i class="bi bi-plus-circle px-2"></i>Abonar</a></li>
                                            <li @click="editPayment(data.value)"><a class="dropdown-item" href="#"> <i
                                                        class="bi bi-plus-circle px-2"></i>Editar</a></li>
                                            <li @click="showHistorial(data.value.id_payment);"><a class="dropdown-item"
                                                    href="#"> <i class="bi bi-card-list px-2"></i>Historial</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Historial-->
        <!-- Historial -->
        <div class="modal-mb" v-if="verHistorial">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Historial de pagos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Cerrar"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="row mx-4">
                                <div class="col-md-2 mb-4">
                                    <label for="historialType" class="form-label">Tipo</label>
                                    <select class="form-select" placeholder="Seleccione un tipo"
                                        aria-label="Seleccione un tipo" v-model="historialType">
                                        <option value="">Ver todos</option>
                                        <option :value="2">Pago a proveedor</option>
                                        <option :value="4">Pago a proveedor (abono directo)</option>
                                        <option :value="3">Pago a proveedor (abono por provisión)</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mt-4">
                                    <div class="text-end">
                                        <a class="btn btn-success" @click="filterHistory()">Filtrar</a>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <vue3-datatable :search="search" :rows="payments" :columns="columns2" order
                                    :sortable="true">
                                    <template #amount="data">
                                        <span>{{ data.value.amount > 0 ?
            formatCurrency(data.value.amount,
                data.value.initials) : 0 }}</span>
                                    </template>

                                    <template #value_payment="data">
                                        <span>{{ data.value.value_payment > 0 ?
            formatCurrency(data.value.value_payment,
                data.value.value_payment_initials) : 0 }}</span>

                                    </template>

                                    <template #unpaid_amount="data">

                                        <span>{{ data.value.unpaid_amount > 0 ?
            formatCurrency(data.value.unpaid_amount,
                data.value.value_unpaid_initials) : 0 }}</span>

                                    </template>
                                    <template #rut="row">
                                        <td style="text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                                    </template>
                                    <!-- Celda personalizada -->
                                    <template #date="row">
                                        <td style="text-align: end; white-space: nowrap;">{{ row.value.date }}</td>
                                    </template>
                                    <template #select="data">
                                        <button @click="toggleSelectionBoard(data.value)">
                                            {{ isSelectedBoard(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ver -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Pago a proveedor</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal2()"
                            aria-label="Cerrar"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card p-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Identificador</b>
                                    <p>{{ supplierPayment.id_payment }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de despacho</b>
                                    <p>{{ supplierPayment.shipping_number }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Estado</b>
                                    <p>{{ supplierPayment.status_name }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Fecha de creación</b>
                                    <p>{{ supplierPayment.date_origin }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>RUT cliente</b>
                                    <p>{{ supplierPayment.rut }}</p>
                                </div>
                                <br>
                                <div class="col-md-4">
                                    <b>Monto deuda original</b>
                                    <p>{{ supplierPayment.amount > 0 ?
            formatCurrency(supplierPayment.amount,
                supplierPayment.initials) : 0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto deuda abonado</b>
                                    <p>{{ supplierPayment.value_payment > 0 ?
            formatCurrency(supplierPayment.value_payment,
                supplierPayment.value_payment_initials) : 0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto impago</b>
                                    <p>{{ supplierPayment.unpaid_amount > 0 ?
            formatCurrency(supplierPayment.unpaid_amount,
                supplierPayment.value_unpaid_initials) :
            0 }}</p>
                                </div>
                            </div>
                            <br>
                            <br>
                            <h6 v-if="supplierPayment.bank_account_origin">DATOS DE PAGO EFECTUADO AL MOMENTO DE ABONAR
                            </h6>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Banco</b>
                                    <p>{{ supplierPayment.bank }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Método de pago</b>
                                    <p>{{ supplierPayment.payment_method == 1 ? 'Transferencia electrónica' : '' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de cuenta</b>
                                    <p>{{ supplierPayment.bank_account_origin }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal2()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";


export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            verHistorial: false,
            historialType: '',
            idHistory: 0,
            verModal: false,
            supplierPayment: {},
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            payments: [],
            items: [

            ],
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                bill_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                amount: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
            },
            columns2: [
                { field: "id_payment_balance", title: "ID" },
                { field: "shipping_number", title: "Nro. de despacho" },
                { field: "date", title: "Fecha" },
                { field: "week_number", title: "Número de semana" },
                { field: "table_type", title: "Tipo" },
                { field: "id_billing_note", title: "Nota de cobro" },
                { field: "rut", title: "RUT cliente" },
                { field: "status_name", title: "Estado" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto de deuda original" },
                { field: "value_payment", title: "Monto abonado" },
                { field: "exchange_rate", title: "Tasa de cambio" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "bank_account_origin", title: "Cuenta de origen" },
                { field: "bank", title: "Banco" },
                { field: "payment_method", title: "Método de pago" },
            ],
            columns: [
                { field: "id_payment", title: "ID" },
                { field: "shipping_number", title: "Nro. de despacho" },
                { field: "date_origin", title: "Fecha" },
                { field: "week_number_origin", title: "Número de semana" },
                { field: "id_billing_note", title: "Notas de cobro asociadas" },
                { field: "rut", title: "RUT cliente" },
                { field: "status_name", title: "Estado" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto de deuda original" },
                { field: "value_payment", title: "Último monto abonado" },
                { field: "unpaid_amount", title: "Monto impago actual" },
                { field: "actions", title: "" },
            ],

        }
    },
    mounted() {
        this.getSP()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        filter() {
            const self = this;

            let params = {
                page: 1,
                per_page: 1000,
                rut: this.dataForm.rut.value,
                nroDespacho: this.dataForm.shipping_number.value,
                bill_number: this.dataForm.bill_number.value,
                monto: this.dataForm.amount.value
            }
            axios.post('/api/getLastPayments', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });



        },
        closeModal() {
            this.verHistorial = false;
        },
        showSupp(value) {
            this.verModal = true;
            this.supplierPayment = value;
        },
        closeModal2() {
            this.verModal = false;
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        toggleDropdown(data) {
            this.$swal({
                title: "Seleccione una accion",
                text: 'puedes generar una nota de cobro o abonar este pago',
                icon: "question",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Abonar",
                denyButtonText: `Generar nota de cobro`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.addPartialPayment(data);//abonar
                } else if (result.isDenied) {
                    this.createBill(data);//emitir nota de cobro
                }
            });
        },
        async filterHistory() {
            let id = this.idHistory;
            let self = this;
            self.verHistorial = false;
            this.$swal({
                title: 'Obteniendo historial de abonos'
            });
            this.$swal.showLoading();
            try {
                let params = {
                    tipo: this.historialType
                }
                const response = await axios.post('/api/getPayments/' + id, params, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                if (response) {
                    this.$swal.close();
                    self.verHistorial = true;
                }
                self.payments = response.data.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async showHistorial(id) {
            this.idHistory = id;
            this.historialType = '';
            let params = {
                tipo: this.historialType
            }
            try {
                const response = await axios.post('/api/getPayments/' + id, params, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response);
                this.verHistorial = true;
                this.payments = response.data.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },

        editPayment(data) {
            window.location.href = '/pagoProveedores/crear/' + data.id_payment;
        },


        addPartialPayment(data) {

            console.dir(data);

            let showBtn = true;
            let nota = "";
            if (data.emitted_notes == "") {
                showBtn = false;
                nota = "Pago sin nota de cobro emitida, ";
                nota += " abonos directos deshabilitados.";
            }
            console.log(showBtn);
            this.$swal({
                title: "¿Deseas abonar este pago?",
                text: nota,
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: showBtn,
                confirmButtonText: "Abonar",
                cancelButtonText: 'Cancelar',
                denyButtonText: `Abonar desde provisiones`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = 'pagoProveedores/abonar/' + data.id_payment + '/' + data.id_client;
                } else if (result.isDenied) {
                    this.getSupply(data)

                }
            });

        },
        async getSupply(data) {
            const self = this;
            try {
                const response = await axios.get('/api/clientSupply/' + data.id_client, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                let supply = response.data; // Array de objetos con el campo "amount"

                // Suma de todos los amounts (asegurándonos de que sean numéricos)
                let totalAmount = supply.reduce((acc, item) => {
                    return acc + Number(item.amount);
                }, 0);

                if (totalAmount > 0) {
                    // Redirige si la suma es mayor que 0
                    window.location.href = 'pagoProveedores/provisionabonar/'
                        + data.id_payment + '/' + data.id_client;
                } else {
                    // Muestra mensaje si no hay saldo
                    this.$swal.fire({
                        icon: 'info',
                        title: 'Sin saldo disponible',
                        text: 'No cuentas con saldo en tus provisiones',
                        confirmButtonText: 'OK'
                    });
                }

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        getSP() {
            this.$swal({
                title: 'Obteniendo pago a proveedores'
            });
            this.$swal.showLoading();
            const self = this;
            let params = {
                page: 1,
                per_page: 1000,
                rut: this.dataForm.rut.value,
                nroDespacho: this.dataForm.shipping_number.value,
                monto: this.dataForm.amount.value,
                bill_number: this.dataForm.bill_number.value
            }

            axios.post('/api/getLastPayments', params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    // self.items = response.data.data.filter(item => item.unpaid_amount > 0)
                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';



.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>