<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>

<template>
    <div class="app-layout">
        <!-- Barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />

        <!-- Contenido principal -->
        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-4">
                <div class="py-4">
                    <div>
                        <!-- Título según si está en modo edición -->
                        <h2 class="fw-normal mb-4 me-3" v-if="edit_mode">
                            <b>Editar compañía de transporte</b>
                        </h2>
                        <h2 class="fw-normal mb-4 me-3" v-else>
                            <b>Crear compañía de transporte</b>
                        </h2>
                    </div>

                    <div class="row p-4">
                        <!-- Nombre -->
                        <div class="col-6 mb-4">
                            <label for="name" class="form-label">Nombre</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.name.err }"
                                id="name" placeholder="" v-model="dataForm.name.value" @blur="handlerBlur" />
                            <span v-if="dataForm.name.err" class="text-danger">
                                Debe ingresar el nombre
                            </span>
                        </div>

                        <!-- RUT -->
                        <div class="col-6 mb-4">
                            <label for="rut" class="form-label">RUT</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }"
                                id="rut" placeholder="" v-model="dataForm.rut.value" @blur="handlerBlur" />
                            <span v-if="dataForm.rut.err" class="text-danger">
                                Debe ingresar un RUT válido
                            </span>
                        </div>

                        <!-- Nombre de contacto -->
                        <div class="col-6 mb-4">
                            <label for="contact_name" class="form-label">Nombre de contacto</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.contact_name.err }" id="contact_name" placeholder=""
                                v-model="dataForm.contact_name.value" @blur="handlerBlur" />
                            <span v-if="dataForm.contact_name.err" class="text-danger">
                                Debe ingresar el nombre de contacto
                            </span>
                        </div>

                        <!-- Teléfono -->
                        <div class="col-6 mb-4">
                            <label for="phone" class="form-label">Teléfono</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.phone.err }"
                                id="phone" placeholder="" v-model="dataForm.phone.value" @blur="handlerBlur" />
                            <span v-if="dataForm.phone.err" class="text-danger">
                                Debe ingresar el teléfono
                            </span>
                        </div>

                        <!-- Correo -->
                        <div class="col-6 mb-4">
                            <label for="email" class="form-label">Correo</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.email.err }"
                                id="email" placeholder="" v-model="dataForm.email.value" @blur="handlerBlur" />
                            <span v-if="dataForm.email.err" class="text-danger">
                                Debe ingresar un correo electrónico válido
                            </span>
                        </div>

                        <!-- País -->
                        <div class="col-6 mb-4">
                            <label for="country" class="form-label">País</label>
                            <select class="form-control" :class="{ 'border-danger': dataForm.country.err }" id="country"
                                v-model="dataForm.country.value">
                                <option value="" disabled>Seleccione un país</option>
                                <option v-for="country in countries" :key="country" :value="country">
                                    {{ country }}
                                </option>
                            </select>
                            <span v-if="dataForm.country.err" class="text-danger">
                                Debe ingresar el país
                            </span>
                        </div>
                    </div>

                    <div class="text-center text-lg-end mt-4 pt-2">
                        <!-- Botones según si es edición o creación -->
                        <template v-if="edit_mode">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem; background-color: #5a50e0;"
                                @click="createOrEdittransportCompany()">
                                Guardar
                            </button>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem; background-color: #5a50e0;"
                                @click="createOrEdittransportCompany()">
                                Crear
                            </button>
                        </template>

                        <a class="btn btn-md text-white bg-danger"
                            style="padding-left: 2.5rem; padding-right: 2.5rem; background-color: #5a50e0;"
                            href="/transportCompanies">
                            Cancelar
                        </a>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import regionCities from '@/regionCities.js';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') === 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            roles: [],
            idtransportCompany: 0,
            countries: ["Chile", "Argentina", "Perú", "Brasil"],
            isChile: false,

            dataForm: {
                sending: false,
                rut: { value: '', err: false },
                name: { value: '', err: false },
                phone: { value: '', err: false },
                email: { value: '', err: false },
                country: { value: '', err: false },
                contact_name: { value: '', err: false },
                status: { value: 1, err: false },
            }
        };
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.id_client = this.user.company.id_company;

        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');

        this.edit_mode = false;
        this.edit_id = 0;
        this.transportCompany = {};

        // Si la URL tiene 3 segmentos y el último es un número => edición
        if (parts.length === 3 && !isNaN(Number(parts[2]))) {
            this.edit_mode = true;
            this.edit_id = Number(parts[2]);
            this.getTransportCompany(this.edit_id);
        }
    },
    methods: {
        updateCollapsed(value) {
            this.collapsed = value;
        },
        updatePinned(value) {
            this.pinned = value;
        },

        // Obtener datos para edición
        getTransportCompany(id_transportCompany) {
            this.$swal({ title: 'Cargando...' });
            this.$swal.showLoading();

            axios.get(`/api/transportCompany/${id_transportCompany}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(response => {
                    this.$swal.close();
                    if (response.status === 200) {
                        this.transportCompany = response.data;
                        this.dataForm.rut.value = this.transportCompany.rut;
                        this.dataForm.name.value = this.transportCompany.name;
                        this.dataForm.email.value = this.transportCompany.email;
                        this.dataForm.status.value = this.transportCompany.status;
                        this.dataForm.contact_name.value = this.transportCompany.contact_name;
                        this.dataForm.phone.value = this.transportCompany.phone;
                        this.dataForm.country.value = this.transportCompany.country;
                    } else {
                        this.transportCompany = {};
                    }
                })
                .catch(error => {
                    this.$swal.close();
                    if (error.response?.statusText === 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    } else if (error.response?.status === 404) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Compañía de transporte no encontrada",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "Error interno al obtener datos de la compañía",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                });
        },

        // Rut: validaciones básicas
        validaRut(rut) {
            if (typeof rut !== 'string' || !rut.includes("-")) {
                return false;
            }
            if (!/^([1-9]\d{0,2}(\.?\d{3})*)-?[\dkK]$/.test(rut)) {
                return false;
            }
            rut = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
            let t = parseInt(rut.slice(0, -1), 10);
            let m = 0, s = 1;
            while (t > 0) {
                s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
                t = Math.floor(t / 10);
            }
            const v = s > 0 ? '' + (s - 1) : 'K';
            return v === rut.slice(-1);
        },
        preformatRut(rut) {
            if (rut.length < 2 || rut.includes("-")) {
                return rut;
            }
            return [rut.slice(0, -1), "-", rut.slice(-1)].join('');
        },
        formatRut(rut, withDots) {
            const parts = rut.split('-');
            const nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },

        // Validación de email
        validateEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },

        // Crear o editar compañía
        createOrEdittransportCompany() {
            this.dataForm.sending = true;
            let foundError = false;

            // Ajustar RUT
            this.dataForm.rut.value = this.preformatRut(this.dataForm.rut.value);
            if (!this.dataForm.rut.value) {
                this.dataForm.rut.err = true;
                foundError = true;
            } else {
                this.dataForm.rut.value = this.formatRut(this.dataForm.rut.value, true);
                this.dataForm.rut.err = false;
            }

            // Validar nombre
            if (!this.dataForm.name.value) {
                this.dataForm.name.err = true;
                foundError = true;
            } else {
                this.dataForm.name.err = false;
            }

            // Validar correo
            if (!this.dataForm.email.value) {
                this.dataForm.email.err = true;
                foundError = true;
            } else if (!this.validateEmail(this.dataForm.email.value)) {
                this.dataForm.email.err = true;
                foundError = true;
            } else {
                this.dataForm.email.err = false;
            }

            // Validar país
            if (!this.dataForm.country.value) {
                this.dataForm.country.err = true;
                foundError = true;
            } else {
                this.dataForm.country.err = false;
            }

            // Validar nombre de contacto
            if (!this.dataForm.contact_name.value) {
                this.dataForm.contact_name.err = true;
                foundError = true;
            } else {
                this.dataForm.contact_name.err = false;
            }

            if (foundError) {
                return false;
            }

            let loadingMsg = this.edit_mode ? 'Editando compañía...' : 'Creando compañía...';
            this.$swal({ title: loadingMsg });
            this.$swal.showLoading();

            let urlpost = '/api/transportCompany';
            if (this.edit_mode) {
                urlpost = `/api/transportCompany/${this.edit_id}?_method=PATCH`;
            }

            axios.post(urlpost, {
                email: this.dataForm.email.value,
                contact_name: this.dataForm.contact_name.value,
                country: this.dataForm.country.value,
                name: this.dataForm.name.value,
                rut: this.formatRut(this.dataForm.rut.value, false),
                phone: this.dataForm.phone.value,
                status: this.dataForm.status.value,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(response => {
                    this.$swal.close();
                    if (response.status === 201) {
                        // Creación exitosa
                        this.$swal({
                            title: "Compañía de transporte agregada exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then(() => {
                            window.location.href = '/transportCompany';
                        });
                    } else if (response.status === 200) {
                        // Edición exitosa
                        this.$swal({
                            title: "Compañía de transporte editada exitosamente",
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: true,
                        }).then(() => {
                            window.location.href = '/transportCompany';
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "Error al procesar la solicitud",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                })
                .catch(error => {
                    this.$swal.close();

                    // Si la sesión expiró
                    if (error.response?.statusText === 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                        return;
                    }

                    // Mostrar mensajes de error de validación
                    const errData = error.response?.data?.errors || {};
                    if (errData.email) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "El correo ya existe en el sistema.",
                            showConfirmButton: true
                        });
                    } else if (errData.rut) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "El RUT ya existe en el sistema.",
                            showConfirmButton: true
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "Error al crear o editar la compañía. Comuníquese con soporte.",
                            showConfirmButton: true
                        });
                    }
                });
        }
    }
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
