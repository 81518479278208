<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>

<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        
        <!-- Contenido principal -->
        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="">Lista de compañías de transporte</h2>
                    <div class="text-end">
                        <a class="btn btn-success" href="transportCompany/crear">Agregar compañía de transporte</a>
                    </div>
                </div>
                
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay compañías de transporte ingresadas</h2>
                </div>
                
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs" placeholder="Buscar compañías..." />
                    </div>

                    <vue3-datatable 
                        :search="search"
                        :rows="items" 
                        :columns="columns" 
                        order 
                        :sortable="true"
                        :sortColumn="'ID'"
                    >
                        <template #id="data">
                            <div :class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.id }}
                            </div>
                        </template>

                        <template #name="data">
                            <div :class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                {{ data.value.name }}
                            </div>
                        </template>

                        <template #status="data">
                            <div :class="(data.value.status != 1) ? 'text-danger' : 'text-dark'">
                                <strong>{{ data.value.status == 1 ? 'HABILITADO' : 'DESHABILITADO' }}</strong>
                            </div>
                        </template>

                        <!-- Celda personalizada -->
                        <template #actions="data">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Acciones
                                </button>
                                <ul class="dropdown-menu">
                                    <li @click="editarTransportCompany(data.value.id_transport_company)">
                                        <a class="dropdown-item" href="#">
                                            <i class="bi bi-pencil px-2"></i>Editar
                                        </a>
                                    </li>
                                    <li v-if="data.value.status == 1" @click="disableTransportCompany(data.value.id_transport_company)">
                                        <a class="dropdown-item" href="#">
                                            <i class="bi bi-toggle2-off px-2"></i>Deshabilitar
                                        </a>
                                    </li>
                                    <li v-else @click="enableTransportCompany(data.value.id_transport_company)">
                                        <a class="dropdown-item" href="#">
                                            <i class="bi bi-toggle2-on px-2"></i>Habilitar
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";

import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [],
            verModal: false,
            transportCompanyesSelect: [],
            columns: [
                { field: "id", title: "ID", filter: false },
                { field: "rut", title: "Rut" },
                { field: "name", title: "Nombre" },
                { field: "contact_name", title: "Nombre contacto" },
                { field: "email", title: "Correo" },
                { field: "status", title: "Estado" },
                { field: 'actions', title: '', sortable: false },
            ]
        }
    },
    mounted() {
        this.getTransportCompanys();
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        closeModal2(){
            this.verModal = false;
        },
        updateCollapsed(value) {
            this.collapsed = value;
        },
        updatePinned(value) {
            this.pinned = value;
        },
        editarTransportCompany(id) {
            window.location.href = 'transportCompany/' + id;
        },
        changeStatus(id, id_status, msg, msg_error) {
            const self = this;
            let urlpost = '/api/transportCompany/' + id + '?_method=PATCH'; // para editar

            axios.post(urlpost,
            {
                status: id_status,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                self.$swal.close();
                if (response.status == 200) {
                    self.$swal.fire({
                        title: msg,
                        icon: "info",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        this.getTransportCompanys();
                    });
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: msg_error,
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                }
            }).catch(function (error) {
                self.$swal.close();

                if (error.response.statusText) {
                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    } else {
                        console.log(error.response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Problema al modificar la compañía de transporte detectado, comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Problema interno detectado, comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                }
            });
        },

        disableTransportCompany(id) {
            const self = this;
            this.$swal.fire({
                title: "¿Está seguro de que desea deshabilitar esta compañía de transporte?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, deshabilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeTransportCompanyStatus(
                        id,
                        2,
                        "Compañía de transporte deshabilitada exitosamente",
                        "Error al deshabilitar la compañía de transporte"
                    );
                }
            });
        },

        enableTransportCompany(id) {
            const self = this;
            this.$swal.fire({
                title: "¿Está seguro de que desea habilitar esta compañía de transporte?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, habilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeTransportCompanyStatus(
                        id,
                        1,
                        "Compañía de transporte habilitada exitosamente",
                        "Error al habilitar la compañía de transporte"
                    );
                }
            });
        },

        // Ajustar el método para reutilizar en enable/disable
        changeTransportCompanyStatus(id, newStatus, successMsg, errorMsg) {
            this.changeStatus(id, newStatus, successMsg, errorMsg);
        },

        getTransportCompanys() {
            this.$swal({
                title: 'Obteniendo compañías de transporte'
            });
            this.$swal.showLoading();

            axios.get('/api/transportCompany', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                if (response.status == 200) {
                    this.items = response.data;
                    this.$swal.close();
                } else {
                    this.items = [];
                }
            }).catch(error => {
                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout';
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>
