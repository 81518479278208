<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="">Servicios por cobrar</h2>
                    <p>Servicios que serán cobrados al cliente por la gestión de los despachos.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="serviciosCobrar/crear">Agregar servicio por cobrar</a>
                    </div>

                </div>
                <p>Filtros</p>
                <div class="m-3 row">
                    <div class="col-md-2 mb-4">
                        <label for="rut" class="form-label">Rut cliente</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }" id="rut"
                            placeholder="" v-model="dataForm.rut.value">
                        <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                            rut</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="shipping_number" class="form-label">Nro despacho</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.shipping_number.err }" id="shipping_number"
                            placeholder="" v-model="dataForm.shipping_number.value">
                        <span v-if="dataForm.shipping_number.err" class="text-danger">Debe ingresar un
                            número de despacho</span>
                    </div>

                    <div class="col-md-2 mb-4">
                        <label for="document_number" class="form-label">Nro de factura</label>
                        <input type="text" class="form-control"
                            :class="{ 'border-danger': dataForm.document_number.err }" id="document_number"
                            placeholder="" v-model="dataForm.document_number.value">
                        <span v-if="dataForm.document_number.err" class="text-danger">Debe ingresar un
                            número de documento</span>
                    </div>
                    <div class="col-md-2 mb-4">
                        <label for="amount" class="form-label">Monto</label>
                        <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                            id="amount" placeholder="" v-model="dataForm.amount.value">
                        <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                    </div>
                    <div class="col-md-2 mt-4">
                        <div class="text-end">
                            <a class="btn btn-success" @click="filter()">Filtrar</a>
                        </div>
                    </div>
                </div>
                <div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{ data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0
                                }}</span>
                        </template>
                        <template #value_payment="data">
                            <span>{{ data.value.value_payment > 0 ? formatCurrency(data.value.value_payment,
            data.value.value_payment_initials) : 0 }}</span>

                        </template>
                        <template #unpaid_amount="data">
                            <span>{{ data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount,
            data.value.value_unpaid_initials) : 0 }}</span>
                        </template>
                        <template #rut="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                        </template>
                        <!-- Celda personalizada -->
                        <template #date_fee="row">
                            <td style=" text-align: end; white-space: nowrap;">{{ row.value.date_fee }}</td>
                        </template>
                        <template #actions="data">
                            <div class="text-center">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Acciones
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li @click="showService(data.value)"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-eye px-2"></i>Ver</a></li>
                                        <li @click="addPartialPayment(data.value)"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-plus-circle px-2"></i>Abonar</a></li>
                                        <li @click="editService(data.value)"><a class="dropdown-item" href="#"> <i
                                                    class="bi bi-pencil px-2"></i>Editar</a></li>
                                        <li @click="showHistorial(data.value.id_cs_fee);"><a class="dropdown-item"
                                                href="#"> <i class="bi bi-card-list px-2"></i>Historial</a></li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
        <!-- Ver -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Servicio por cobrar</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal2()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card p-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Identificador</b>
                                    <p>{{ service.id_cs_fee }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de despacho</b>
                                    <p>{{ service.shipping_number }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Estado</b>
                                    <p>{{ service.status_name }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Fecha de creación</b>
                                    <p>{{ service.date_fee }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Rut cliente</b>
                                    <p>{{ service.rut }}</p>
                                </div>
                              
                                <br>
                                <div class="col-md-4">
                                    <b>Monto deuda original</b>
                                    <p>{{ service.amount > 0 ?
            formatCurrency(service.amount,
                service.initials) : 0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto deuda abonado</b>
                                    <p>{{ service.value_payment > 0 ?
            formatCurrency(service.value_payment,
                service.initials) : 0 }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Monto impago</b>
                                    <p>{{ service.unpaid_amount > 0 ?
            formatCurrency(service.unpaid_amount,
                service.initials) : 0 }}</p>
                                </div>

                            </div>
                            <br>
                            <br>
                            <h6 v-if="service.bank_account_origin">DATOS DE PAGO EFECTUADO AL MOMENTO DE ABONAR
                            </h6>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Banco</b>
                                    <p>{{ service.bank }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Método de pago</b>
                                    <p>{{ service.payment_method == 1 ? 'Transferencia electronica' : '' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <b>Número de cuenta</b>
                                    <p>{{ service.bank_account_origin }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal2()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>
        <!-- Historial-->
        <div class="modal-mb" v-if="verHistorial">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Historial de pagos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Cerrar"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="row mx-4">
                                <div class="col-md-2 mb-4">
                                    <label for="historialType" class="form-label">Tipo</label>
                                    <select id="historialType" class="form-select" v-model="historialType"
                                        aria-label="Seleccione un tipo">
                                        <option value="">Ver todos</option>
                                        <option :value="1">Servicio por cobrar</option>
                                        <option :value="5">Servicio por cobrar (abono directo)</option>
                                        <option :value="6">Servicio por cobrar (abono por provisión)</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mt-4 text-end">
                                    <button class="btn btn-success" @click="filterHistory()">Filtrar</button>
                                </div>
                            </div>
                            <div>
                                <vue3-datatable :search="search" :rows="payments" :columns="columns2" order
                                    :sortable="true" :sortColumn="'ID'">
                                    <template #amount="data">
                                        <span>{{ formatCurrency(data.value.amount, data.value.initials) }}</span>
                                    </template>

                                    <template #value_payment="data">
                                        <span v-if="data.value.value_payment != 0">
                                            {{ formatCurrency(data.value.value_payment,
            data.value.value_payment_initials) }}
                                        </span>
                                        <span v-else>
                                            {{ formatCurrency(0.00, data.value.initials) }}
                                        </span>
                                    </template>

                                    <template #unpaid_amount="data">
                                        <span v-if="data.value.unpaid_amount != 0">
                                            {{ formatCurrency(data.value.unpaid_amount,
            data.value.value_unpaid_initials) }}
                                        </span>
                                        <span v-else>
                                            {{ formatCurrency(0.00, data.value.initials) }}
                                        </span>
                                    </template>

                                    <template #rut="row">
                                        <td style="text-align: end; white-space: nowrap;">{{ row.value.rut }}</td>
                                    </template>

                                    <template #date="row">
                                        <td style="text-align: end; white-space: nowrap;">{{ row.value.date }}</td>
                                    </template>

                                    <template #select="data">
                                        <button @click="toggleSelectionBoard(data.value)"
                                            aria-label="Seleccionar o deseleccionar">
                                            {{ isSelectedBoard(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            theme: localStorage.getItem('theme') || 'light-mode',
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            service: {},
            verModal: false,
            verHistorial: false,
            historialType: '',
            idHistory: 0,
            items: [

            ],
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                document_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },

                amount: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
            }, columns2: [
                { field: "id_balance", title: "ID" },
                { field: "shipping_number", title: "Nro. de despacho" },
                { field: "date_origin", title: "Fecha" },
                { field: "week_number_origin", title: "Número de semana" },
                { field: "table_type", title: "Tipo" },
                { field: "bill_number", title: "Nota de cobro" },
                { field: "rut", title: "RUT cliente" },
                { field: "status_name", title: "Estado" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto de deuda original" },
                { field: "value_payment", title: "Monto abonado" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "bank_account_origin", title: "Cuenta de origen" },
                { field: "bank", title: "Banco" },
                { field: "payment_method", title: "Método de pago" }
            ],
            columns: [
                { field: "id_cs_fee", title: "ID" },
                { field: "shipping_number", title: "Nro. de despacho" },
                { field: "date_origin", title: "Fecha" },
                { field: "week_number_origin", title: "Número de semana" },
                { field: "bill_number", title: "Factura" },
                { field: "rut", title: "RUT cliente" },
                { field: "status_name", title: "Estado" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto de deuda original" },
                { field: "value_payment", title: "Último monto abonado" },
                { field: "unpaid_amount", title: "Monto impago actual" },
                { field: "actions", title: "" }
            ],
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        filter() {
            const self = this;

            let params = {
                rut: this.dataForm.rut.value, nroDespacho: this.dataForm.shipping_number.value,
                nroFactura: this.dataForm.document_number.value, monto: this.dataForm.amount.value
            }
            axios.post('/api/services-to-be-collected', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });



        },
        addPartialPayment(data) {
            this.$swal({
                title: "¿Deseas abonar este pago?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Abonar",
                cancelButtonText: 'Cancelar',
                denyButtonText: `Abonar desde provisiones`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = 'serviciosCobrar/abonar/' + data.id_cs_fee + '/' + data.id_client;
                } else if (result.isDenied) {
                    this.getSupply(data)
                }
            });

        },
        async getSupply(data) {
            const self = this;
            try {
                const response = await axios.get('/api/clientSupply/' + data.id_client, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                let supply = response.data; // Array de objetos con el campo "amount"

                // Suma de todos los amounts (asegurándonos de que sean numéricos)
                let totalAmount = supply.reduce((acc, item) => {
                    return acc + Number(item.amount);
                }, 0);

                if (totalAmount > 0) {
                    // Redirige si la suma es mayor que 0
                    window.location.href = 'serviciosCobrar/provisionabonar/' + data.id_cs_fee + '/' + data.id_client;
                } else {
                    // Muestra mensaje si no hay saldo
                    this.$swal.fire({
                        icon: 'info',
                        title: 'Sin saldo disponible',
                        text: 'No cuentas con saldo en tus provisiones',
                        confirmButtonText: 'OK'
                    });
                }

            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },
        async filterHistory() {
            let id = this.idHistory;
            let self = this;
            self.verHistorial = false;
            this.$swal({
                title: 'Obteniendo historial de abonos'
            });
            this.$swal.showLoading();
            try {
                let params = {
                    tipo: this.historialType
                }
                const response = await axios.post('/api/services-history-collected/' + id, params, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                if (response) {
                    this.$swal.close();
                    self.verHistorial = true;
                }
                self.payments = response.data.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async showHistorial(id) {
            this.idHistory = id;
            this.historialType = '';
            try {
                let params = {
                    tipo: ''
                }
                const response = await axios.post('/api/services-history-collected/' + id, params, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.verHistorial = true;
                this.payments = response.data.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }

        },
        showService(value) {
            this.verModal = true;
            this.service = value;
        },
        closeModal2() {
            this.verModal = false;
        },
        closeModal() {
            this.verHistorial = false;
        },
        editService(data) {
            window.location.href = '/serviciosCobrar/' + data.id_cs_fee;
        },
        formatCurrency(value, currencyCode = 'CLP') {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo servicios por cobrar'
            });
            this.$swal.showLoading();
            const self = this;
            let params = {
                rut: this.dataForm.rut.value, tipo: null, nroDespacho: this.dataForm.shipping_number.value,
                nroFactura: this.dataForm.document_number.value, monto: this.dataForm.amount.value
            }
            axios.post('/api/services-to-be-collected', params, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';



.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>